module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-source-wordpress/gatsby-browser.js'),
      options: {"plugins":[],"excludeFieldNames":["lastEditedBy","comment","authorDatabaseId","dateGmt","desiredSlug","enclosure","enqueuedScripts","enqueuedStylesheets","guid","isContentNode","isTermNode","isPostPage","isPrivacyPage","isTermNode","srcSet"],"url":"https://lanneboapi.triggerfish.cloud/lannebo-ohman-se-swedish/graphql","schema":{"timeout":2000000,"perPage":100,"requestConcurrency":10,"queryDepth":25,"circularQueryLimit":5,"typePrefix":"Wp","previewRequestConcurrency":5},"verbose":false,"debug":{"timeBuildSteps":false,"disableCompatibilityCheck":false,"throwRefetchErrors":false,"preview":false},"html":{"useGatsbyImage":false,"imageMaxWidth":180,"imageQuality":100,"fallbackImageMaxWidth":1024,"createStaticFiles":true,"generateWebpImages":true,"generateAvifImages":false,"placeholderType":"dominantColor"},"type":{"Post":{},"Page":{},"Comment":{"limit":0},"Menu":{"limit":null},"MenuItem":{"limit":null},"MonthlyReport":{"excludeFieldNames":["contentNodes","seo","ancestors","author","template","lastEditedBy","authorDatabaseId","authorId","dateGmt","desiredSlug","enclosure","isContentNode","isTermNode","modified","parentDatabaseId","parentId","srcSet","parent","children"]},"MediaItem":{"createFileNodes":false,"localFile":{"requestConcurrency":600,"excludeByMimeTypes":[],"maxFileSizeBytes":15728640},"excludeFieldNames":["contentNodes","seo","ancestors","author","template","lastEditedBy","authorDatabaseId","authorId","contentTypeName","dateGmt","desiredSlug","enclosure","isContentNode","isTermNode","modified","parentDatabaseId","parentId","srcSet","parent","children"],"placeholderSizeName":"gatsby-image-placeholder","lazyNodes":false},"RootQuery":"{ excludeFieldNames: ['viewer', 'node', 'schemaMd5'], },"},"catchLinks":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-sentry/gatsby-browser.js'),
      options: {"plugins":[],"dsn":"","environment":"production","enabled":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"lang":"sv","name":"Lannebo Fonder","short_name":"Lannebo Fonder","description":"Lannebo Fonder","theme_color":"#fff","background_color":"#fff","icon":"static/meta-images/icon.png","include_favicon":false,"start_url":"/","display":"minimal-ui","cache_busting_mode":"name","legacy":true,"theme_color_in_head":true,"crossOrigin":"anonymous","cacheDigest":"e5e05652b2003d63c40d948b638d4237"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
